/* eslint-disable */
import Swiper from 'swiper/bundle'
import CookieConsent from 'vanilla-cookieconsent'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import mapboxgl from 'mapbox-gl'
import Cookies from 'js-cookie';

export default {
    init() {
        // JavaScript to be fired on all pages

        let scroll = $(window).scrollTop();
        let windowWidth = $(window).width();
        let mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
        mapboxgl.accessToken = 'pk.eyJ1IjoiYmFyY2Vsb25hLWNvIiwiYSI6ImNsbm9mZmN3bzBpM2Yya29kcWYxbnZpcGkifQ.gsHaJQAk_Ua4vBbt3DxNGQ';

        $(function () {        

            if ($('#addToCal').length > 0) {
                new atc(document.querySelector('#addToCal'),{
                    labels: false,
                    timezone: "Europe/Paris",
                })
            }

            $(window).scroll(function () {
                scroll = $(window).scrollTop();
                posHeader();
            });

            $(window).resize(function () {
                windowWidth = $(window).width();
            });


            // Blur on click
            $('a, button, input[type=\'submit\'], .btn').on('click', function () {
                $(this).blur();
            });


            // Button scroll down
            $('.js_btn-scroll-down').on('click', function () {
                $('html,body').stop().animate({
                    scrollTop: $(this).closest('.module').offset().top + $(this).closest('.module').outerHeight(true),
                }, 1000);
            });





            // ========================================
            // Header
            // ========================================
            $('.js_toggle-menu').on('click', function () {
                if (windowWidth <= 1024) {
                    $(this).toggleClass('close');
                    $('html').toggleClass('disable-scroll');
                    $('body').toggleClass('menu-active');
                }
            });

            $('.js_copy-link').on('click', function () {
                let URL = document.location.href; 
                navigator.clipboard.writeText(URL);
                if (windowWidth > 1024) {
                    $(this).toggleClass('copied')
                }
            });

            $('.js_secret-menu-trigger').on('click', function () {
                $('#header').toggleClass('secret-menu-reveal')
                if ($('.secret-menu-trigger').hasClass('secret-menu-trigger-close')) {
                    $('.secret-menu-trigger').addClass('secret-menu-trigger-reverse').removeClass('secret-menu-trigger-close');
                } else if ($('.secret-menu-trigger').hasClass('secret-menu-trigger-close')) {                    
                        $('.secret-menu-trigger').addClass('secret-menu-trigger-close').removeClass('secret-menu-trigger-reverse');
                } else {
                    $('.secret-menu-trigger').addClass('secret-menu-trigger-close')
                }          
            });

            $('.js_toggle-mega-menu').on('click', function () {
                if (windowWidth > 1024) {
                    $(this).toggleClass('close');
                    $('body').toggleClass('mega-menu-active');
                    if ($('body').hasClass('mega-menu-active')) {
                        $('html').toggleClass('disable-scroll');
                    } else {
                        setTimeout(function () {
                            $('html').toggleClass('disable-scroll');
                        }, 300);
                    }
                }
            });

            $('#header .menu-primary .menu > li.menu-item-has-children > a').on('click', function (e) {
                if (windowWidth <= 1024) {
                    e.preventDefault();
                    let $elt = $(this);
                    $elt.closest('li').siblings('li').find('a').removeClass('active');
                    $elt.closest('li').siblings('li').find('.sub-menu').slideUp(400);
                    $elt.toggleClass('active').next('.sub-menu').slideToggle(400);
                }
            });

            $('#header .mega-menu-primary .menu > li.menu-item-has-children > a').on('click', function (e) {
                if (windowWidth > 1024) {
                    e.preventDefault();
                    let $elt = $(this);
                    $elt.closest('li').siblings('li').removeClass('active');
                    $elt.closest('li').siblings('li').find('> .sub-menu').slideUp(400);
                    $elt.closest('li').toggleClass('active').find('.sub-menu').first().slideToggle(400);
                }
            });

            $('.js_btn-toggle-search').on('click', function () {
                $('body').toggleClass('search-active');
                // if ($('body').hasClass('search-active')) {
                    // $('html').addClass('disable-scroll');
                // } else {
                    $('html').toggleClass('disable-scroll');
                // }
                setTimeout(function () {
                    if ($('body').hasClass('search-active')) {
                        $('.search-form .search-field').focus();
                    }
                }, 100);
            });

            let position = $(window).scrollTop();
            let keepHeaderVisible = false;
            let headerSticky = 0;
            let $header = $('#header');
            let headerHeight;
            let headerTop;
            let $headerContent = jQuery('.header-page-content');

            function posHeader() {
                headerHeight = $header.outerHeight(true);
                headerTop = $('#header').offset().top;

                if (scroll <= headerTop) {
                    $('body').removeClass('scroll-up scroll-down');
                } else if (scroll > headerTop) {
                    $('body').addClass('scroll-down').removeClass('scroll-up');
                } else {
                    $('body').addClass('scroll-up').removeClass('scroll-down');
                }

                if ($(document).width() > 1024) {
                    if (scroll > position) {
                        // Scroll down
                        if (scroll > headerHeight + headerTop) {
                            // Cache le header
                            headerSticky = headerHeight + headerTop;
                            $header.addClass('transition');
                        } else {
                            if (!keepHeaderVisible) {
                                // Cache le header progressivement suivant le scroll
                                headerSticky = scroll;
                                $header.removeClass('transition');
                            }
                        }
                    } else {
                        // Scroll Up
                        if (scroll > headerHeight + headerTop) {
                            // Affiche le header
                            headerSticky = headerTop;
                            $header.addClass('transition');
                            keepHeaderVisible = true; // Garde le header visible jusqu'en haut de page
                        } else if (keepHeaderVisible && scroll > headerTop) {
                            headerSticky = headerTop;
                            $header.addClass('transition');
                        } else {
                            // Affiche le header progressivement suivant le scroll
                            headerSticky = scroll;
                            $header.removeClass('transition');
                            keepHeaderVisible = false; // Garde le header visible jusqu'en haut de page
                        }
                    }
                    if (scroll <= 0) {
                        headerSticky = 0;
                        keepHeaderVisible = false;
                    }

                    $('#header .header-wrapper').css('transform', 'translateY(-' + headerSticky + 'px)');

                    position = scroll;
                } else {
                    $('#header .header-wrapper').css('transform', '');
                }
            }

            posHeader();





            // ========================================
            // Footer
            // ========================================
            $('.js_footer-btn-scroll').on('click', function () {
                $('html,body').stop().animate({
                    scrollTop: 0,
                }, 1500);
            });





            // ========================================
            // Modules
            // ========================================
            // Module Hero
            if ($('.module-hero .item').length > 1) {
                new Swiper('.js_slider-hero', {
                    loop: true,
                    speed: 1000,
                    autoplay: {
                        delay: 4000,
                        disableOnInteraction: true,
                    },
                    navigation: {
                        nextEl: '.js_slider-hero-btn-next',
                        prevEl: '.js_slider-hero-btn-prev',
                    },
                });
            }


            // Module News slider
            $('.js_news-slider').each(function (index) {
                let elt = this;
                $(elt).siblings('.js_news-slider-btn-prev, .js_news-slider-btn-next').addClass('index-' + index);

                let params = {
                    loop: true,
                    speed: 750,
                    navigation: {
                        nextEl: '.js_news-slider-btn-next.index-' + index,
                        prevEl: '.js_news-slider-btn-prev.index-' + index,
                    },
                };

                if ($(elt).hasClass('columns-2')) {
                    params.spaceBetween = 26;
                    params.slidesPerView = 1;
                    params.breakpoints = {
                        601: {
                            slidesPerView: 2,
                        },
                    };
                } else if ($(elt).hasClass('columns-3')) {
                    params.spaceBetween = 26;
                    params.slidesPerView = 1;
                    params.breakpoints = {
                        601: {
                            slidesPerView: 2,
                        },
                        1025: {
                            slidesPerView: 3,
                        },
                    };
                } else {
                    params.spaceBetween = 26;
                    params.slidesPerView = 'auto';
                }

                new Swiper(elt, params);

            });


            // Module Events slider
            $('.js_events-slider').each(function (index) {
                let elt = this;
                $(elt).siblings('.js_events-slider-btn-prev, .js_events-slider-btn-next').addClass('index-' + index);

                let params = {
                    loop: true,
                    speed: 750,
                    slidesPerView: 1,
                    spaceBetween: 26,
                    navigation: {
                        nextEl: '.js_events-slider-btn-next.index-' + index,
                        prevEl: '.js_events-slider-btn-prev.index-' + index,
                    },
                    breakpoints : {
                        601: {
                            slidesPerView: 1
                        },
                        1025: {
                            slidesPerView: 3,
                        },
                    },
                };
                if (windowWidth > 1024) {
                    if ($(elt).find('.item').length > 3) {
                        new Swiper(elt, params);
                    }
                }
                if (windowWidth > 600) {
                    if ($(elt).find('.item').length > 1) {
                        new Swiper(elt, params);
                    }
                }
            });


            // Module Logos slider
            $('.js_logos-slider').each(function (index) {
                let elt = this;
                let params;
                $(elt).siblings('.js_logos-slider-btn-prev, .js_logos-slider-btn-next').addClass('index-' + index);
                if ($(elt).hasClass('module-in-column')) {
                    params = {
                        speed: 750,
                        slidesPerView: 3,
                        autoplay: {
                            delay: 3000,
                            disableOnInteraction: true,
                        },
                        navigation: {
                            nextEl: '.js_logos-slider-btn-next.index-' + index,
                            prevEl: '.js_logos-slider-btn-prev.index-' + index,
                        },
                    }
                }
                else {
                    params = {
                        // loop: true,
                        speed: 750,
                        slidesPerView: 2,
                        spaceBetween: 20,
                        autoplay: {
                            delay: 3000,
                            disableOnInteraction: true,
                        },
                        navigation: {
                            nextEl: '.js_logos-slider-btn-next.index-' + index,
                            prevEl: '.js_logos-slider-btn-prev.index-' + index,
                        },
                        breakpoints: {
                            481: {
                                slidesPerView: 2,
                            },
                            601: {
                                slidesPerView: 3,
                            },
                            961: {
                                slidesPerView: 4,
                                spaceBetween: 26,
                            },
                            1025: {
                                slidesPerView: 6,
                            },
                        },
                    }
                }
                if (windowWidth > 1024) {
                    if (!$(elt).hasClass('module-in-column') && $(elt).find('.item').length > 6) {
                        new Swiper(elt, params);
                    } else if ($(elt).hasClass('module-in-column') && $(elt).find('.item').length > 3) {
                        new Swiper(elt, params);
                }
                } else if (windowWidth > 960) {
                    if ($(elt).find('.item').length > 4) {
                        new Swiper(elt, params);
                    }
                } else if (windowWidth > 600) {
                    if ($(elt).find('.item').length > 3) {
                        new Swiper(elt, params);
                    }
                } else if (windowWidth > 480) {
                    if ($(elt).find('.item').length > 2) {
                        new Swiper(elt, params);
                    }
                } else {
                    if ($(elt).find('.item').length > 2) {
                        new Swiper(elt, params);
                    }
                }
            });


            // Module Accordion
            $('.js_toggle-accordion').on('click', function () {
                let $elt = $(this);
                let speed = 400;
                if ($elt.hasClass('active')) {
                    $elt.closest('.accordion').find($('.title')).removeClass('active').next('.txt').slideUp(speed);
                } else {
                    $elt.closest('.accordion').find($('.title')).removeClass('active').next('.txt').slideUp(speed);
                    $elt.addClass('active').next('.txt').slideDown(speed);
                }
            });


            // Module Images slider
            let sliderImage = [];
            $('.js_images-slider').each(function (index) {
                let elt = this;
                if ($(elt).find('.item').length > 1) {
                    $(elt).data('index', index); // Utilisé pour la popin du module gallery
                    $(elt).siblings('.js_images-slider-btn-prev, .js_images-slider-btn-next, .js_images-slider-pagination').addClass('index-' + index);

                    sliderImage[index] = new Swiper(elt, {
                        loop: true,
                        speed: 750,
                        navigation: {
                            nextEl: '.js_images-slider-btn-next.index-' + index,
                            prevEl: '.js_images-slider-btn-prev.index-' + index,
                        },
                        pagination: {
                            el: '.js_images-slider-pagination.index-' + index,
                            type: 'bullets',
                            clickable: true,
                        },
                    });
                }
            });


            // Module Video
            $('.js_btn-video').on('click', function () {
                let $elt = $(this);
                let $video = $elt.closest('.video-wrapper');
                let $iframe = $video.find('iframe');
                let src = '';
                if ($elt.data('src')) {
                    src = 'https://www.youtube-nocookie.com/embed/' + $elt.data('src') + '?rel=0&showinfo=0&enablejsapi=1&autoplay=1';
                } else if ($elt.data('src-vimeo')) {
                    src = 'https://player.vimeo.com/video/' + $elt.data('src-vimeo') + '?rel=0&showinfo=0&enablejsapi=1&autoplay=1';
                } else if ($elt.data('src-dailymotion')) {
                    src = 'https://geo.dailymotion.com/player.html?video=' + $elt.data('src-dailymotion');
                }
                $iframe.attr('src', src);
                $video.addClass('active');
                setTimeout(function () {
                    $iframe[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
                }, 600);
            });


            // Module References
            $('.js_references-slider').each(function () {
                let elt = this;
                if ($(elt).find('.sub-item').length > 1) {
                    new Swiper(elt, {
                        loop: true,
                        speed: 750,
                        allowTouchMove: false,
                        navigation: {
                            nextEl: '.js_references-slider-btn-next',
                            prevEl: '.js_references-slider-btn-prev',
                        },
                        pagination: {
                            el: '.js_references-slider-pagination',
                            type: 'bullets',
                            clickable: true,
                        },
                    });
                }
            });
            $('.js_references-popin-slider').each(function () {
                let elt = this;
                if ($(elt).find('.item').length > 1) {
                    new Swiper(elt, {
                        loop: true,
                        speed: 750,
                        navigation: {
                            nextEl: '.js_references-popin-slider-btn-next',
                            prevEl: '.js_references-popin-slider-btn-prev',
                        },
                        pagination: {
                            el: '.js_references-popin-slider-pagination',
                            type: 'bullets',
                            clickable: true,
                        },
                    });
                }
            });


            // Module Map
            $('.js_load-map').each(function () {
                let elt = this;
                let markers = $(elt).attr('data-markers');
                const map = new mapboxgl.Map({
                    container: $(elt).attr('id'),
                    style: 'mapbox://styles/mapbox/standard-beta',
                    center: [$(elt).attr('data-lng'), $(elt).attr('data-lat')],                    
                    zoom: 17,                    
                    pitch: 65,
                });
                map.scrollZoom.disable();
                map.addControl(new mapboxgl.NavigationControl());

                var coor = jQuery.parseJSON(markers);
                var bounds = new mapboxgl.LngLatBounds();

                coor.forEach(function (key) {
                    const el = document.createElement('div');
                    const containerLogo = document.createElement('div')
                    containerLogo.setAttribute('class', 'container-pin');

                    const logo = document.createElement("IMG");
                    logo.setAttribute('class', 'img-pin');
                    logo.src=$(elt).attr('data-pin');

                    el.className = 'marker';
                    containerLogo.append(logo);
                    el.append(containerLogo);
                    new mapboxgl.Marker(el)
                        .setLngLat([key[0], key[1]])
                        .addTo(map);

                    bounds.extend(key);
                });

                // permet d'ajuster le zoom en fonction des markers
                map.fitBounds(bounds, {padding: 100});
                var mapLat = map.getBounds().getCenter().lat;
                var mapLong = map.getBounds().getCenter().long;
                map.setCenter = (mapLat, mapLong);
                if (coor.length == 1) {
                    var zoomAjust = 1;
                    var mapZoom = map.getZoom();
                    var newZoom = mapZoom - zoomAjust;
                    map.setZoom(newZoom);
                }
            });


            // Module Text video slider
            let sliderTextVideo = [];
            $('.js_text-video-slider').each(function (index) {
                let elt = this;
                if ($(elt).find('.item').length > 1) {
                    $(elt).siblings('.js_text-video-slider-btn-prev, .js_text-video-slider-btn-next').addClass('index-' + index);
                    $(elt).find('.js_text-video-slider-btn-prev, .js_text-video-slider-btn-next').addClass('index-' + index);
                    sliderTextVideo[index] = new Swiper(elt, {
                        loop: true,
                        speed: 750,
                        navigation: {
                            nextEl: '.js_text-video-slider-btn-next.index-' + index,
                            prevEl: '.js_text-video-slider-btn-prev.index-' + index,
                        },
                    });
                }
            });





            // ========================================
            // Popin
            // ========================================
            function openPopin(popin, slide) {
                $('.popin-wrapper[data-popin="' + popin + '"]').show(0).addClass('active');
                $('html').addClass('disable-scroll');
                if (slide) {
                    sliderImage[$('.popin-wrapper[data-popin="' + popin + '"]').find('.slider').data('index')].slideTo(slide);
                }
            }

            function closePopin(popin) {
                if (popin) {
                    $('.popin-wrapper[data-popin="' + popin + '"]').removeClass('active');
                    setTimeout(function () {
                        $('html').removeClass('disable-scroll');
                        $('.popin-wrapper[data-popin="' + popin + '"]').hide(0);
                    }, 600);
                } else {
                    $('.popin-wrapper').removeClass('active');
                    setTimeout(function () {
                        $('html').removeClass('disable-scroll');
                        $('.popin-wrapper').hide(0);
                    }, 600);
                }
            }

            $(document).on('click', '.js_open-popin', function () {
                openPopin($(this).data('popin'), $(this).data('slide'));
            });


            $(document).on('click', '.js_close-popin', function () {
                closePopin();
            });
            $(document).on('click', '.popin-wrapper', function (e) {
                if (e.target !== this) {
                    return;
                }
                closePopin();
            });





            // ========================================
            // Popin Alert
            // ========================================
            $(document).on('click', '.js_close-popin-alert, .btn-wrapper, .popin-alert-wrapper', function (e) {
                if ($(this).hasClass('popin-alert-wrapper') && e.target !== this) {
                    return;
                }
                Cookies.set('alerte', 'alertClose', { expires: 30, path: '/' });  // Cookie valable 30 jours
                closePopin('alert');
            });
            
            // Vérifier l'état du cookie pour afficher ou masquer la popin
            let alerte = $('.popin-alert-wrapper');
            let alerteCookie = Cookies.get('alerte');
            
            if (alerteCookie === 'alertClose') {
                closePopin('alert');
            } else if (alerte.length > 0) {
                openPopin('alert');
            }





            // ========================================
            // Animations
            // ========================================
            gsap.registerPlugin(ScrollTrigger);


            // Main
            gsap.to('#main', {duration: .7, opacity: 1});


            // Pour activer / désactiver les animations
            if (!$('body').hasClass('woocommerce')) {
                $('.module.has-background-image.background-parallax').each(function (index, elt) {
                    gsap.to($(elt).find('.background'), {
                        yPercent: 25,
                        ease: "none",
                        scrollTrigger: {
                            trigger: elt,
                            scrub: true
                        },
                    });
                });


                // Module Accordion
                $('.module-accordion').each(function (index, elt) {
                    let tlModuleAccordion = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleAccordion
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.title'), {
                            duration: .7,
                            x: 75,
                            opacity: 0,
                            stagger: 0.1,
                        }, '-=.5')
                });


                // Module Clickable
                $('.module-clickable .items').each(function (index, elt) {
                    let tlModuleClickable = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleClickable
                        .from($(elt).prev('.title-module'), .7, {y: 25, opacity: 0})
                        .from($(elt).find('.item'), {
                            duration: .7,
                            y: 75,
                            opacity: 0,
                            stagger: 0.1,
                        }, '-=.5')
                });


                // Module Contact
                $('.module-contact').each(function (index, elt) {
                    let tlModuleContact = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleContact
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.col-1'), {duration: .7, x: -75, opacity: 0}, '-=.5')
                        .from($(elt).find('.col-2'), {duration: .7, x: 75, opacity: 0}, '-=.7')
                });


                // Module Form
                $('.module-form').each(function (index, elt) {
                    let tlModuleForm = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleForm
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.gfield'), {
                            duration: .7,
                            y: 75,
                            opacity: 0,
                            stagger: 0.1,
                        }, '-=.5')
                        .from($(elt).find('.gform_footer'), {duration: .7, y: 25, opacity: 0})
                });


                // Module Gallery
                $('.module-gallery').each(function (index, elt) {
                    let tlModuleGallery = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleGallery
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.tabs'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                        .from($(elt).find('.list .item'), {
                            duration: .7,
                            y: 75,
                            opacity: 0,
                            stagger: 0.1,
                        }, '-=.6')
                });


                // Module Head text
                $('.module-head-text').each(function (index, elt) {
                    gsap.from($(elt).find('.col'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        duration: .7,
                        y: 75,
                        opacity: 0,
                        stagger: 0.1,
                    });
                });


                // Module Hero - list
                $('.module-hero.mode-list').each(function (index, elt) {
                    gsap.from($(elt).find('.item:nth-child(1)'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        duration: .7,
                        x: -75,
                        opacity: 0,
                    });
                    gsap.from($(elt).find('.item:nth-child(2)'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        duration: .7,
                        x: 75,
                        opacity: 0,
                    });
                });


                // Module Icons
                $('.module-icons').each(function (index, elt) {
                    let tlModuleIcons = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleIcons
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.list .item'), {
                            duration: .7,
                            y: 75,
                            opacity: 0,
                            stagger: 0.1,
                        }, '-=.5')
                });


                // Module Images slider
                $('.module-images-slider:not(.popin-wrapper .module-images-slider)').each(function (index, elt) {
                    let tlModuleImagesSlider = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleImagesSlider
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.slider-wrapper'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                });


                // Module Images vidéos parallax
                $('.module-images-videos-parallax .item').each(function (index, elt) {
                    // Video
                    let eltVideo = $(elt).find('.video');
                    if (eltVideo.length > 0) {
                        ScrollTrigger.create({
                            trigger: eltVideo,
                            start: 'top 100%',
                            end: 'bottom 0',
                            onEnter: () => {
                                eltVideo[0].currentTime = 0;
                                eltVideo[0].play();
                            },
                            onEnterBack: () => {
                                eltVideo[0].currentTime = 0;
                                eltVideo[0].play();
                            },
                            onLeave: () => eltVideo[0].pause(),
                            onLeaveBack: () => eltVideo[0].pause(),
                        });
                    }

                    if (windowWidth > 1024) {
                        let eltDesc = $(elt).find('.desc');

                        // First item
                        if ($(elt).is(':first-child')) {

                            // Fixe media
                            let tlModuleImagesVideosParallax = gsap.timeline({
                                scrollTrigger: {
                                    trigger: elt,
                                    start: 'top 0%',
                                    end: 'bottom 100%',
                                    pin: $(elt).find('.media-wrapper'),
                                    toggleActions: "restart reverse restart reverse",
                                },
                            })

                            // Parallax image
                            gsap.to($(elt).find('.illus-content'), {
                                scrollTrigger: {
                                    trigger: elt,
                                    scrub: true,
                                    start: 'top 0%',
                                    end: 'bottom 100%',
                                },
                                y: '-15vh',
                            });

                            // Hide desc
                            gsap.to($(elt).find('.desc'), {
                                scrollTrigger: {
                                    trigger: eltDesc,
                                    scrub: true,
                                    start: 'top +=300',
                                    end: 'top 0',
                                },
                                opacity: 0,
                            });

                            // Only one item
                            if (!$(elt).is(':last-child')) {
                                // Hide media
                                gsap.to($(elt).find('.media'), {
                                    scrollTrigger: {
                                        trigger: elt,
                                        scrub: true,
                                        start: 'bottom 130%',
                                        end: 'bottom 100%',
                                    },
                                    opacity: 0.1,
                                });
                            }

                        } else {

                            // Fixe media
                            let tlModuleImagesVideosParallax = gsap.timeline({
                                scrollTrigger: {
                                    trigger: elt,
                                    start: 'top 100%',
                                    end: 'top 0',
                                    pin: $(elt).find('.media-wrapper'),
                                },
                            });

                            // Show media
                            gsap.to($(elt).find('.media'), {
                                scrollTrigger: {
                                    trigger: elt,
                                    scrub: true,
                                    start: 'top 100%',
                                    end: "top 65%",
                                },
                                opacity: 1,
                            });

                            if (!$(elt).is(':last-child')) {
                                // Hide media
                                gsap.from($(elt).find('.media'), {
                                    scrollTrigger: {
                                        trigger: elt,
                                        scrub: true,
                                        start: 'top 45%',
                                        end: "top 0",
                                        immediateRender: false,
                                        toggleActions: "restart reverse restart reverse",
                                    },
                                    opacity: 1,
                                });
                            }

                            // Parallax image
                            gsap.to($(elt).find('.illus-content'), {
                                scrollTrigger: {
                                    trigger: elt,
                                    scrub: true,
                                    start: 'top 100%',
                                    end: 'top 0',
                                },
                                y: '-15vh',
                            });

                            // Show desc
                            gsap.from($(elt).find('.desc'), {
                                scrollTrigger: {
                                    trigger: eltDesc,
                                    scrub: true,
                                    start: '50% 100%',
                                    end: "+=300",
                                },
                                opacity: 0,
                            });

                            if (!$(elt).is(':last-child')) {
                                // Hide desc
                                gsap.to($(elt).find('.desc'), {
                                    scrollTrigger: {
                                        trigger: eltDesc,
                                        scrub: true,
                                        start: 'top +=300',
                                        end: "top 0",
                                        immediateRender: false,
                                    },
                                    opacity: 0,
                                });
                            }

                        }
                    }
                });


                // Module Key figures
                $('.module-key-figures').each(function (index, elt) {
                    let tlModuleKeyFigures = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleKeyFigures
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.list .item'), {
                            duration: .7,
                            y: 75,
                            opacity: 0,
                            stagger: 0.1,
                        }, '-=.5')

                    gsap.to($(elt).find('.nbr'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        innerText: function(index, elt) { return elt.dataset.nbr },
                        snap: { innerText: 1 },
                        stagger: 0.1,
                        duration: 1,
                    });
                });


                // Module Link
                $('.module-link').each(function (index, elt) {
                    let tlModuleLink = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleLink
                        .from($(elt).find('.btn-wrapper'), {duration: .7, y: 75, opacity: 0})
                });


                // Module Logos slider
                $('.module-logos-slider').each(function (index, elt) {
                    let tlModuleLogosSlider = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleLogosSlider
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.slider-wrapper'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                });


                // Module Map
                $('.module-map').each(function (index, elt) {
                    let tlModuleMap = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleMap
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.map-wrapper'), {duration: .7, opacity: 0}, '-=.5')
                });


                // Module News slider
                $('.module-news-slider').each(function (index, elt) {
                    let tlModuleNewsSlider = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleNewsSlider
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.slider-wrapper'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                        .from($(elt).find('.btn-more-wrapper'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                });


                // Module Ornament
                $('.module-ornament').each(function (index, elt) {
                    gsap.from($(elt), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 100%',
                        },
                        duration: .7,
                        opacity: 0,
                    });
                });

                // Module Ornament - image parallax
                $('.module-ornament .illus-wrapper').each(function (index, elt) {
                    gsap.to($(elt).find('.illus'), {
                        scrollTrigger: {
                            trigger: elt,
                            scrub: true,
                            start: 'top bottom',
                            end: 'bottom top',
                        },
                        y: '14vh',
                    });
                });


                // Module Quote
                $('.module-quote').each(function (index, elt) {
                    let tlModuleQuote = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleQuote
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.txt'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                        .from($(elt).find('.illus-wrapper'), {duration: .7, y: 75, opacity: 0}, '-=.6')
                        .from($(elt).find('.name'), {duration: .7, y: 75, opacity: 0}, '-=.6')
                        .from($(elt).find('.function'), {duration: .7, y: 75, opacity: 0}, '-=.6')
                });


                // Module Posts list
                $('.module-posts-list').each(function (index, elt) {
                    let tlModulePostList = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModulePostList
                        .from($(elt).find('.title-module'), .7, {y: 25, opacity: 0})
                        .from($(elt).find('.item'), {
                            duration: .7,
                            y: 75,
                            opacity: 0,
                            stagger: 0.1,
                        }, '-=.5')
                });


                // Module References
                $('.module-references').each(function (index, elt) {
                    let tlModuleReferences = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleReferences
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.tabs'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                        .from($(elt).find('.list .item'), {
                            duration: .7,
                            y: 75,
                            opacity: 0,
                            stagger: 0.1,
                        }, '-=.5')
                        .from($(elt).find('.btn-more-wrapper'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                });

                // Module Team
                $('.module-team').each(function (index, elt) {
                    let tlModuleTeam = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleTeam
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.tabs'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                        .from($(elt).find('.list .item'), {
                            duration: .7,
                            y: 75,
                            opacity: 0,
                            stagger: 0.1,
                        }, '-=.6')
                });

                // Module Text
                $('.module-text').each(function (index, elt) {
                    let tlModuleText = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleText
                        .from($(elt).find('.txt'), {duration: .7, y: 75, opacity: 0})
                        .from($(elt).find('.btn-wrapper'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                });


                // Module Text image/video
                $('.module-text-image').each(function (index, elt) {
                    gsap.from($(elt).find('.img-left .col-1'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        duration: .7,
                        x: -75,
                        opacity: 0,
                    });
                    gsap.from($(elt).find('.img-left .col-2'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        duration: .7,
                        x: 75,
                        opacity: 0,
                    });
                    gsap.from($(elt).find('.img-right .col-1'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        duration: .7,
                        x: 75,
                        opacity: 0,
                    });
                    gsap.from($(elt).find('.img-right .col-2'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        duration: .7,
                        x: -75,
                        opacity: 0,
                    });
                });

                // Module Text image/video - image parallax
                $('.module-text-image.img-parallax .illus-wrapper').each(function (index, elt) {
                    gsap.from($(elt).find('.illus'), {
                        scrollTrigger: {
                            trigger: elt,
                            scrub: true,
                            start: 'top bottom',
                            end: 'bottom top',
                            toggleActions: "restart reverse restart reverse",
                        },
                        y: '200px',
                        ease: 'none',
                    });
                });


                // Module Vidéo/image
                $('.module-video').each(function (index, elt) {
                    let tlModuleVideo = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleVideo
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.video-wrapper, .illus-wrapper'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                });

                // Module Text image/video - image parallax
                $('.module-video.img-parallax .illus-wrapper').each(function (index, elt) {
                    gsap.from($(elt).find('.illus'), {
                        scrollTrigger: {
                            trigger: elt,
                            scrub: true,
                            start: 'top bottom',
                            end: 'bottom top',
                            toggleActions: "restart reverse restart reverse",
                        },
                        y: '200px',
                        ease: 'none',
                    });
                });


                // Module Text video slider
                $('.module-text-video-slider').each(function (index, elt) {
                    let tlModuleTextVideoSlider = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleTextVideoSlider
                        .from($(elt).find('.slider-wrapper'), {duration: .7, y: 75, opacity: 0})
                });


                // Page Archive news
                $('.page-archive-news').each(function (index, elt) {
                    gsap.from($(elt).find('.title-page-wrapper'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        duration: .7,
                        y: 25,
                        opacity: 0,
                    });
                    gsap.from($(elt).find('.tabs'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        duration: .7,
                        y: 75,
                        opacity: 0,
                    });
                    gsap.from($(elt).find('.list-single .item'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        duration: .7,
                        y: 75,
                        opacity: 0,
                        stagger: 0.1,
                    });
                });


                // Page Archive references
                $('.page-archive-references').each(function (index, elt) {
                    gsap.from($(elt).find('.title-page-wrapper'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        duration: .7,
                        y: 25,
                        opacity: 0,
                    });
                });
            }


            // Loader
            if ($('.loader').length > 0) {
                gsap.to('.loader img', {duration: 1.1, y: -75, ease: 'power1.inOut'}, '+1.5');
                gsap.to('.loader', {duration: .9, opacity: 0}, '+1.8');
                gsap.to('.loader', {duration: 0, className: 'loader disable'}, '+2.8');
            }


            // Event Toogle
            let eventToggle = document.getElementById("js_event-toggle");
            let toggleList = document.getElementById("js_select-list");
            let toggleGrid = document.getElementById("js_select-grid");
            let elt = document.getElementById('js_list-event');
            if (elt) {
                if (windowWidth > 1024) {
                    eventToggle.addEventListener("click", function() {
                        event.stopPropagation();
                        eventToggle.classList.toggle('active');
                    });
                    toggleList.addEventListener("click", function() {
                        event.stopPropagation();
                        eventToggle.classList.remove('active');
                        jQuery(elt).removeClass('grid').addClass('list');
                        document.getElementById("toggle-text").innerHTML = "Vue liste";
                        toggleList.classList.add('selected');
                        toggleGrid.classList.remove('selected');
                    });
                    toggleGrid.addEventListener("click", function() {
                        event.stopPropagation();
                        eventToggle.classList.toggle('active');
                        jQuery(elt).removeClass('list').addClass('grid');
                        document.getElementById("toggle-text").innerHTML = "Vue photo";
                        toggleGrid.classList.add('selected');
                        toggleList.classList.remove('selected');
                    });
                } else {
                    jQuery(elt).removeClass('list').addClass('grid');
                }                
            }
            $(document).on('click', '#js_event-toggle', function (e) {                
                if (e.target !== this) {
                    eventToggle.classList.remove('active');                    
                }
            });
            $('.js_toggle-menu').on('click', function () {
                if (windowWidth <= 1024) {
                    $(this).toggleClass('close');
                    $('html').toggleClass('disable-scroll');
                    $('body').toggleClass('menu-active');
                }
            });

            // ========================================
            // Cookie
            // ========================================
            let cc = initCookieConsent();
            let hasReloaded = false;

            const setMapCookie = function(cookie) {
                let cooks = Cookies.get();
                if (cookie.categories && cookie.categories.includes('advertising')) {
                    if (!cooks.map) {
                        Cookies.set('map', 1, { expires: 182, path: '/' });
                    }
                } else {
                    if (cooks.map) {
                        Cookies.remove('map', { path: '/' });
                    }
                }
            };

            cc.run({
                current_lang: 'fr',
                gui_options: { 
                    consent_modal: {
                        layout: 'box',
                        position: 'bottom left', 
                        transition: 'slide',
                    },
                    settings_modal: {
                        layout: 'box',
                        transition: 'slide',
                    },
                },
                onAccept: function(cookie) {
                },
                onFirstAction: function(cookie) {
                },
                onChange: function(cookie) {
                    setMapCookie(cookie);
                    if (!hasReloaded) {
                        hasReloaded = true;
                        window.location.reload();
                    }
                },
                languages: { 
                    'fr': {
                        consent_modal: {
                            description: '<span id="c-ttl">Hello ! voici des <span class="large">Cookies</span></span></br>Ce site utilise des cookies essentiels pour assurer son bon fonctionnement et des cookies de suivi pour comprendre comment vous interagissez avec lui. Ce dernier ne sera fixé qu\'après accord.<br><button type="button" data-cc="c-settings" class="cc-link">Choisir mes préférences</button><br><button href="/politique-de-confidentialite/" class="cc-link">Voir la politique de confidentialité</button>',
                            primary_btn: {
                                text: 'Tout accepter',
                                role: 'accept_all',
                            },
                            secondary_btn: {
                                text: 'Tout rejeter',
                                role: 'accept_necessary'
                            },
                        },
                        settings_modal: {
                            save_settings_btn: 'Enregistrer',
                            accept_all_btn: 'Tout accepter',
                            reject_all_btn: 'Tout rejeter',
                            close_btn_label: 'Fermer',
                            cookie_table_headers: [
                                {col1: 'Nom'},
                                {col2: 'Domaine'},
                                {col3: 'Expiration'},
                                {col4: 'Description'},
                            ],
                            blocks: [
                                {
                                    description: '<span id="s-ttl">Gestion de préférence des cookies</span><br>Sur ce site, nous utilisons des cookies pour mesurer notre audience. Vous pouvez sélectionner ici les services que vous allez autoriser.',
                                }, {
                                    title: 'Cookies nécessaires au bon fonctionnement du site',
                                    description: 'Ces cookies sont indispensables au bon fonctionnement du site internet. Ces cookies sont essentiels au bon fonctionnement du site, vous ne pouvez pas les désactiver.',
                                    toggle: {
                                        value: 'necessary',
                                        enabled: true,
                                        readonly: true,
                                    },
                                }, {
                                    title: 'Publicitaires (ou presque)',
                                    description: 'Pour concevoir ce site nous avons fait appel à une société bien de chez nous (à Nîmes pour être précis) et cette société s’appuie pour rendre votre site plus dynamique sur des technologies tierces : YouTube, openmap, etc… Pour profiter de l’ensemble des technologies nous vous invitons à accepter ces cookies.',
                                    toggle: {
                                        value: 'advertising',
                                        enabled: false,
                                        readonly: false,
                                    },
                                }, {
                                    title: 'Google Analytics',
                                    description: 'Ces cookies permettent d\'analyser les statistiques de consultation de notre site.',
                                    toggle: {
                                        value: 'analytics',
                                        enabled: false,
                                        readonly: false,
                                    },
                                },
                            ],
                        },
                    },
                },
            });

            // Gestion des cookies au chargement
            let ccName = cc.getConfig('cookie_name');
            let ccCookie = Cookies.get(ccName);
            let mapCookie = Cookies.get('map');
            if (mapCookie !== undefined && ccCookie === undefined) {
                Cookies.remove('map', { path: '/' });
            }
            if (ccCookie !== undefined && mapCookie === undefined) {
                let cookie = JSON.parse(ccCookie);
                if (cookie.categories && cookie.categories.includes('advertising')) {
                    Cookies.set('map', 1, { expires: 182, path: '/' });
                }
            }

        });

        $(window).on('load', function() {

            // Anchor for Chrome
            var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
            if (window.location.hash && isChrome) {
                setTimeout(function () {
                    var hash = window.location.hash;
                    window.location.hash = "";
                    window.location.hash = hash;
                }, 300);
            }

        });
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};